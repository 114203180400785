.blog {float: left;width: 100%;
  &__Box {float: left;width: 100%;border-bottom: 1px solid rgba(206, 29, 0, 0.1);
    &--left {float: left;width: 40%;top: 50%;position: relative;transform:translateY(-50%);}
    &--right {float: left;width: 60%;top: 50%;position: relative;transform:translateY(-50%);}
    &--image {width: 210px;height: 260px;background-size: cover;background-repeat: no-repeat;background-position: center center;float: left;}
    img {max-width: 120px;}
    span {font-weight: 600;font-size: 12px;color: $black;margin-bottom: 10px;}
    h2 {margin: 0;font-weight: 600;line-height: 1.4;font-size: 32px;color: $black;transition:300ms;
      &:hover {text-decoration: underline;}
    }
    a {text-decoration: none;}
  }
  &__Inner {max-width: 800px;margin: auto;position: relative;height: 460px;}
  &__More {width: 150px;height: 40px;display: inline-block;background: $brand2;border-radius: 100px;line-height: 40px;transition:300ms;
    a {text-decoration: none;color: $white;height: 40px;line-height: 40px;text-align: center;float: left;width: 100%;}
    h4 {margin: 0;font-size: 16px;font-weight: 600;line-height: 40px;}
    &:hover {background: $red;}
  }
  .paginationWrap {text-align: center;float: left;width: 100%;padding: 40px 0;
    span {float: left;width: 100%;text-align: center;}
  }
}

.singlePost {float: left;width: 100%;border-top: 1px solid rgba(206, 29, 0, 0.1);
  &__Inner {max-width: 680px;margin: auto;padding: 80px 0;position: relative;}
  &__Cover {width: 100%;background-size: cover;background-repeat: no-repeat;background-position: center center;height: 700px;}
  h2 {font-size: 48px;font-weight: 600;line-height: 1.2;margin: 0;}
  h3 {font-weight: 300;margin: 0;font-size: 32px;line-height: 1.4;margin-bottom: 70px;}
  h4.date {margin: 0;font-size: 12px;font-weight: 600;margin-bottom: 10px;}
  p {font-size: 18px;line-height: 1.7;}
   a {position: relative;color: $black;text-decoration: none;position: relative;z-index: 1;
       &:before {content: '';position: absolute;left: 0;right: 0;bottom: 0px;height: 1px;background: $black;transition:300ms;z-index: -10;}
  &:after {content: '';position: absolute;left: -15px;bottom:-2px;height: 5px;width: 15px;z-index: -1;background: $white;transition:600ms;}
  &:hover {
    &:after {left: 140%;}
  }
    }
  &__Navigation {float: left;width: 100%;padding: 50px 0;border-top: 1px solid rgba(206, 29, 0, 0.1);position: relative;
    &:before {top: 0;right: 50%;bottom: 0;width: 1px;background: rgba(206, 29, 0, 0.1);z-index: -1;position: absolute;content: '';}
    &--prev {float: left;width: 50%;text-align: center !important;position: relative;z-index: 1;
      
    

      i {margin-right: 15px;font-family: Arial;font-weight: bold;font-size: 16px;}
    }
    &--next {float: right;width: 50%;text-align: center;
      i {margin-left: 15px;font-family: Arial;font-weight: bold;font-size: 16px;}
    }

    a {text-decoration: none;color: $black;font-weight: 600;font-size: 16px;

      span {position: relative;z-index: 1;
  &:before {content: '';position: absolute;left: 0;right: 0;bottom: -2px;height: 1px;background: $black;transition:500ms;z-index: -10;}
  &:after {content: '';position: absolute;left: -20px;bottom:-2px;height: 5px;width: 15px;z-index: -1;background: $white;transition:600ms;}
  &:hover {
    &:after {left: 100%;}
  }}
    }
  }
  &__Social {
    position: absolute;left: -100px;
    ul {margin: 0;padding: 0;visibility: hidden;opacity: 0;transform:translateY(20px);transition:400ms;
      li {height: 35px;width: 35px;border: 1px solid $black;line-height: 30px;text-align: center;border-radius: 100%;margin-bottom: 10px;
        a {
          &:before {display: none;}
          &:after {display: none;}
          svg {vertical-align: middle;fill: $brand;}
          &:hover {
            svg {
              path {fill: $brand;}
            }
          }
        }
      }
    }
  }
  &__sCaller {
   span { height: 35px;width: 35px;border: 1px solid #dddddd;line-height: 30px;text-align: center;border-radius: 100%;margin-bottom: 10px;cursor: pointer;display: block;transition:400ms;
      svg {vertical-align: middle;}
    }

  &:hover {
    span {border-color: $black;}
  }
  }
}
.singlePost__sCaller:hover .singlePost__sMenu{opacity: 1;visibility: visible;transform:translateY(0);}