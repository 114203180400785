.homeSlider {margin-bottom: 0 !important;float: left;width: 100%;overflow: hidden;
    &__Slide {height: 100vh !important;float: left;width: 100%;position: relative;background-size: cover;background-repeat: no-repeat;width: 100%;z-index: 1;
        &:before {content: '';z-index: -1;position: absolute;top: 0;right: 0;bottom: 0;left: 0;background: $black;opacity: 0.5;}
        .center {height: 100vh;}
    }
    h2 {font-size: 56px;color: $white;position: relative;z-index: 1;font-weight: bold;margin-bottom: 0;text-transform: uppercase;}
    h4 {font-size: 30px;font-weight: 200;color: $white;margin: auto;margin-top: 0;margin-bottom: 20px;}
    img {max-width: 200px;margin-bottom: 40px;}
    &__More {padding:20px 30px;text-transform: initial;color: $white;vertical-align: middle;border: 1px solid $white;border-top: 0;border-bottom: 0;position: relative;z-index: 1;line-height: 70px;outline:none;height: 70px;text-decoration: none;text-transform: uppercase;font-weight: lighter;
          &:before {content: '';position: absolute;top: 0;left: 0;right: 0;height: 1px;background: $white;z-index: -1;transition:300ms;}
          &:after {content: '';position: absolute;bottom: 0;left: 0;right: 0;height: 1px;background: $white;z-index: -1;transition:300ms;}
          &:hover {
            &:before {right: 30%;}
            &:after {left: 30%;}
          }
    }
    &__Inner {float: right;top: 50%;position: relative;transform:translateY(-50%);text-align: right;max-width: 600px;}
.arrowPrev, .arrowNext {display: block;position: absolute;width: 50px;height: 100%;z-index: 10000;text-align: center;}
.arrowPrev {height: 50px;display: block; opacity:1; transition:200ms;color: $white;font-size: 47px;background: alpha($white,0.2);left: 130px;top: 45%;transform:translateY(-50%);
  &:hover {transition:200ms;
    &:before {opacity: 0.6;transition:200ms;}
  }
}
.arrowNext {height: 50px;display: block; opacity:1; transition:200ms;color: $white;font-size: 47px;background: alpha($white,0.2);left: 190px;top: 45%;transform:translateY(-50%);
  &:hover {transition:200ms;
    &:before {opacity: 0.6;transition:200ms;}
  }
}
}
.about {
  float: left;width: 100%;padding: 70px 0;
  h2 {text-align: center;font-size: 56px;text-transform: uppercase;margin: 0;margin-bottom: 70px;}
  h3 {font-size: 20px;margin: 0;margin-bottom: 40px;text-transform: lowercase;color: $black;font-family: $second;letter-spacing: 0px;
    span {color: $black;margin-left: 5px;font-weight: bold;}
  }
  &__Left {width: 30%;float: left;margin-right: 5%;}
  &__Center {width: 31%;margin-left: 2%;margin-right: 2%;float: left;
    ul {padding: 0;margin: 0;
      li {list-style: none;
        img {max-height: 80px;}
        h3 {font-size: 1.5625em;
line-height: 1.45;
letter-spacing: 0.06em;text-transform: uppercase;color: $brand;
          span {color: $black;}
        }
      }
    }
  }
  &__Right {width: 30%;float: left;
    img {max-width: 100%;height: auto;}
  }
}

.blackButton {padding:20px 30px;text-transform: initial;color: $black;vertical-align: middle;border: 1px solid $black;border-top: 0;border-bottom: 0;position: relative;z-index: 1;outline:none;text-decoration: none;text-transform: uppercase;font-weight: lighter;float: left;margin-top: 70px;
          &:before {content: '';position: absolute;top: 0;left: 0;right: 0;height: 1px;background: $black;z-index: -1;transition:300ms;}
          &:after {content: '';position: absolute;bottom: 0;left: 0;right: 0;height: 1px;background: $black;z-index: -1;transition:300ms;}
          &:hover {
            &:before {right: 30%;}
            &:after {left: 30%;}
          }
}
.classicButton {text-decoration: none;color: $black;padding: 8px 15px;display: inline-block;position: relative;z-index: 1;
  &:before {content: '';position: absolute;left: 15px;right: 15px;bottom: 8px;height: 1px;background: $black;transition:300ms;z-index: -10;}
  &:after {content: '';position: absolute;left: 0px;bottom:6px;height: 5px;width: 15px;z-index: -1;background: $white;transition:600ms;}
  &:hover {
    &:after {left: 100%;}
  }
}
.projects {
  float: left;width: 100%;padding: 0 0 70px 0;
  h2 {text-align: center;font-size: 56px;text-transform: uppercase;margin: 0;margin-bottom: 70px;}
  h3 {font-size: 20px;margin: 0;margin-bottom: 40px;text-transform: lowercase;color: $black;font-family: $second;letter-spacing: 0px;
    span {color: $black;margin-left: 5px;font-weight: bold;}
  }
  &__More {padding:20px 30px;text-transform: initial;color: $white;vertical-align: middle;border: 1px solid $white;border-top: 0;border-bottom: 0;position: relative;z-index: 1;line-height: 70px;outline:none;height: 70px;text-decoration: none;text-transform: uppercase;font-weight: lighter;
          &:before {content: '';position: absolute;top: 0;left: 0;right: 0;height: 1px;background: $white;z-index: -1;transition:300ms;}
          &:after {content: '';position: absolute;bottom: 0;left: 0;right: 0;height: 1px;background: $white;z-index: -1;transition:300ms;}
          &:hover {
            &:before {right: 30%;}
            &:after {left: 30%;}
          }
    }
  &__Box {
    float: left;width: 25%;height: 550px !important;background-size: cover;padding: 30px;position: relative;z-index: 1;background-position: center center;background-repeat: no-repeat;
    h4 {color: $white;text-transform: uppercase;font-size: 30px;margin: 0;opacity: 1;transition:300ms;line-height: 1.45;letter-spacing: 0.06em;word-spacing:10000px;}
    p {color: $white;font-weight: lighter;font-size: 24px;opacity: 0;transition:300ms;}
    &:before {content: '';position: absolute;top: 0;right: 0;bottom: 0;left: 0;background: $black;z-index: -1;opacity: 0.4;transition:300ms;}
    a {opacity: 0;transition:300ms;}
    &:hover {
      &:before {opacity: 0.8;}
      h4, p, a {opacity: 1;}
    }
  }
}
.innerNoMargin {padding: 0;}
.years {
  float: left;width: 100%;padding: 0 0 70px 0;text-align: center;
  h2 {text-align: center;font-size: 300px;text-transform: uppercase;margin: 0;color: $brand;  /* Fallback: assume this color ON TOP of image */display: inline-block;
   background: url(../images/za-web.jpg) no-repeat;-webkit-background-clip: text;-webkit-text-fill-color: transparent;background-size: cover;background-position: center left;}
  h3 {text-transform: uppercase;display: inline-block;color: $brand;text-align: left;
font-size: 1.5625em;
line-height: 1.45;
letter-spacing: 0.06em;
    span {color: $black;display: block;}
  }
}

.clients {
  float: left;width: 100%;padding: 0 0 70px 0;
  h2 {text-align: center;font-size: 56px;text-transform: uppercase;margin: 0;margin-bottom: 40px;}
  &__Inner {max-width: 760px;margin: auto;text-align: center;}
  &__Box {
    display: inline-block;width: 16%;margin-left: 1.5%;margin-right: 1.5%;height: 150px;background-size: 90% auto;position: relative;z-index: 1;background-position: center center;background-repeat: no-repeat;filter:grayscale(100%);transition:300ms;
   a {float: left;width: 100%;height: 150px;}
   &:hover {filter:grayscale(0);}
  }
  &__More {padding:20px 30px;text-transform: initial;color: $black;vertical-align: middle;border: 1px solid $black;border-top: 0;border-bottom: 0;position: relative;z-index: 1;outline:none;text-decoration: none;text-transform: uppercase;font-weight: lighter;display: inline-block;margin-top: 40px;
          &:before {content: '';position: absolute;top: 0;left: 0;right: 0;height: 1px;background: $black;z-index: -1;transition:300ms;}
          &:after {content: '';position: absolute;bottom: 0;left: 0;right: 0;height: 1px;background: $black;z-index: -1;transition:300ms;}
          &:hover {
            &:before {right: 30%;}
            &:after {left: 30%;}
          }
    }
}