@media screen and (max-width: 800px) { 
  .mobile {display: block;}
.siteHeader__Left {
        &--inner {height: 90px;line-height: 90px;text-align: left;
        img {max-width: auto;height: 70px;margin-left: 15px;}
        }
        &--social {opacity: 0;visibility: hidden;display: none;}
      }
      .nav {height: auto;line-height:initial;
        ul {
          li {display: block;}
        }
      }

.homeSlider {margin-bottom: 0 !important;float: left;width: 100%;overflow: hidden;
    &__Slide {height: 600px !important;float: left;width: 100%;position: relative;background-size: cover;background-repeat: no-repeat;width: 100%;z-index: 1;
        &:before {content: '';z-index: -1;position: absolute;top: 0;right: 0;bottom: 0;left: 0;background: $black;opacity: 0.5;}
        .center {height: 600px;}
    }
    h2 {font-size: 36px;}
    h4 {font-size: 20px;}
    &__Inner {max-width: 80%;margin-left: 10%;margin-right: 10%;}
}
.innerSlider {
  &__Slide {height: 600px !important;}
  &__Inner {width: 80%;margin-left: 10%;margin-right: 10%;}
}
.siteContent {width: 80%;margin-left: 10%;margin-right: 10%;}
.team {
  &__Box {width: 80%;margin-left: 10%;margin-right: 10%;margin-bottom: 10%;}
}
.js-SiteHeader--is-Fixed {position: absolute;}
.about {
padding: 40px 0;
  h2 {font-size: 40px;margin-bottom: 40px;}
  &__Left {width: 80%;float: left;margin-right: 10%;margin-left: 10%;text-align: center !important}
  &__Center {width: 80%;float: left;margin-right: 10%;margin-left: 10%;text-align: center !important}
  &__Right {width: 80%;float: left;margin-right: 10%;margin-left: 10%;text-align: center !important}
}

.blackButton {margin: auto;margin-top: 20px;margin-bottom: 40px;transform:translateX(50%);}
.projects {
  
  padding: 0 0 40px 0;
  h2 {font-size: 40px;}

&__Box {
    padding-top: 100px;
    h4 {margin-left: 20%;}
    p {opacity: 1;margin-left: 20%;font-size: 18px;}
    &:before {opacity: 0.8;}
    a {opacity: 1;margin-left: 20%;height: 40px;line-height: 40px;padding: 10px 30px;}
    &:hover {
      &:before {opacity: 0.8;}
      h4, p, a {opacity: 1;}
    }
  }

}
.years {
    padding: 0 0 40px 0;
}

.clients {
  padding: 0 0 40px 0;
  h2 {font-size: 40px;}
  &__Box {width: 36%;
    &:last-of-type {margin: auto;float: none;display: block;}
  }
  

}
.innerNoMargin {padding: 0 !important;}
.contactMap {width: 100%;}
.contactContent {width: 100%;padding-left: 100px;}
}