.mobile {display: none;}
.siteHeader {position: absolute;float: left;width: 100%;height: 130px;z-index: 100;
  &__Left {width: 20%;float: left;z-index: 100;position: relative;
    &--inner {height: 40vh;width: 100%;background: $white;text-align: center;line-height: 40vh;
      img {max-width: 100px;margin: auto;vertical-align: middle;}
      h2 {margin: 0;}
    }
    &--social {height: 60vh;background: $white;float: left;width: 120px;text-align: center;
      ul {padding: 0;margin: 0;position: relative;top: 50%;transform:translateY(-50%);}
      li {list-style: none;margin-bottom: 10px;}
      a {color: $black;text-decoration: none;transition:300ms;display: block;
        &:hover {color: $brand;}
      }
      i {background: alpha($gray,0.7);height: 40px;width: 40px;line-height: 40px;}
    }
  }  
}
.js-SiteHeader {
    &--is-Active {background: $white;opacity: 1;visibility: visible;width: 100%;margin-top: 120px !important;height: 90px;box-shadow: 0 4px 2px -2px alpha($black,0.3);
      .siteHeader__Left {
        &--inner {height: 90px;line-height: 90px;text-align: left;
        img {max-width: auto;height: 70px;margin-left: 35px;}
        }
        &--social {opacity: 0;visibility: hidden;display: none;}
      }
      .nav {
        a {color: $black;}
      }
    }
    &--is-Fixed {background: $white;position: fixed;left: 0px;width: 100%;-webkit-transition: margin 500ms cubic-bezier(0.77, 0, 0.175, 1);transition: margin 500ms cubic-bezier(0.77, 0, 0.175, 1);top: -120px !important;}
}