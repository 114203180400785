.innerSlider {margin-bottom: 0 !important;float: left;width: 100%;overflow: hidden;
    &__Slide {height: 80vh !important;float: left;width: 100%;position: relative;background-size: cover;background-repeat: no-repeat;width: 100%;z-index: 1;
        &:before {content: '';z-index: -1;position: absolute;top: 0;right: 0;bottom: 0;left: 0;background: $black;opacity: 0.5;}
        .center {height: 80vh;}
    }
    h2 {font-size: 56px;color: $white;position: relative;z-index: 1;font-weight: bold;margin-bottom: 0;text-transform: uppercase;}

    &__Inner {float: right;top: 50%;position: relative;transform:translateY(-50%);text-align: right;max-width: 600px;}
}
.siteName {
  float: left;width: 100%;padding: 80px 0;background-image: url("../images/somo_pattern.png");
  &__Inner {max-width: 680px;margin: auto;position: relative;}
  h2 {font-size: 56px;font-weight: 600;color: $white;margin: 0;}
}

.siteContent {float: left;width: 100%;padding: 70px 0;
  &__Inner {max-width: 680px;margin: auto;position: relative;}
  &__Intro {margin-bottom: 30px;
    h3 {font-size: 26px;margin: 0;font-weight: 300;line-height: 1.4;}
  }
  &__Text {
    p {font-size: 18px;line-height: 1.8;font-weight: 300;}
    strong {font-weight: 700;}
    a {position: relative;color: $black;text-decoration: none;position: relative;z-index: 1;
       &:before {content: '';position: absolute;left: 0;right: 0;bottom: 0px;height: 1px;background: $black;transition:300ms;z-index: -10;}
  &:after {content: '';position: absolute;left: -15px;bottom:-2px;height: 5px;width: 15px;z-index: -1;background: $white;transition:600ms;}
  &:hover {
    &:after {left: 140%;}
  }
    }
  }
  &__More {padding:20px 30px;text-transform: initial;color: $black;vertical-align: middle;border: 1px solid $black;border-top: 0;border-bottom: 0;position: relative;z-index: 1;outline:none;text-decoration: none;text-transform: uppercase;font-weight: lighter;display: inline-block;
          &:before {content: '';position: absolute;top: 0;left: 0;right: 0;height: 1px;background: $black;z-index: -1;transition:300ms;}
          &:after {content: '';position: absolute;bottom: 0;left: 0;right: 0;height: 1px;background: $black;z-index: -1;transition:300ms;}
          &:hover {
            &:before {right: 30%;}
            &:after {left: 30%;}
          }
    }
}
.images {float: left;width: 100%;height: 700px;
  .arrowPrev {position: absolute;left: 25px;top: 0;line-height: 700px;z-index: 10;color: $white;font-size: 60px;opacity: 0;transition:300ms;width: 60px;height: 700px;
  }
  .arrowNext {position: absolute;right: 25px;top: 0;line-height: 700px;z-index: 100;color: $white;font-size: 60px;opacity: 0;transition:300ms;width: 60px;text-align: right;height: 700px;
  }
  .customArrow {
    &--prev {height: 120px;background-image: url("../images/arrow-left.svg");background-size: cover;background-repeat: no-repeat;background-position: center center; width: 60px;float: left;top: 50%;transform:translateY(-50%);position: relative;}
    &--next {height: 120px;background-image: url("../images/arrow-right.svg");background-size: cover;background-repeat: no-repeat;background-position: center center; width: 60px;float: right;top: 50%;transform:translateY(-50%);position: relative;}
  }
  &__Slide {height: 700px !important;background-size: cover;background-repeat: no-repeat;background-position: center center;float: left;width: 100%;
  }
  &:hover {
      .arrowNext, .arrowPrev {opacity: 1;}
    }
}
.projects {margin-bottom: 0 !important;
  .arrowPrev {position: absolute;left: 25px;top: 0;line-height: 550px;z-index: 10;color: $white;font-size: 60px;transition:300ms;width: 60px;height: 550px;
  }
  .arrowNext {position: absolute;right: 25px;top: 0;line-height: 550px;z-index: 100;color: $white;font-size: 60px;transition:300ms;width: 60px;text-align: right;height: 550px;
  }
  .customArrow {
    &--prev {height: 60px;background-image: url("../images/arrow-left.svg");background-size: cover;background-repeat: no-repeat;background-position: center center; width: 60px;float: left;top: 50%;transform:translateY(-50%);position: relative;}
    &--next {height: 60px;background-image: url("../images/arrow-right.svg");background-size: cover;background-repeat: no-repeat;background-position: center center; width: 60px;float: right;top: 50%;transform:translateY(-50%);position: relative;}
  }
}
.contactContent {float: left;width: 50%;background-image: url("../../../../uploads/2017/01/za-web.jpg");background-size: cover;background-repeat: no-repeat;background-position: center center;height: 500px;position: relative;z-index: 1;padding-left: 200px;
&:before {content: '';position: absolute;top: 0;right: 0;bottom: 0;left: 0;background: $black;opacity: 0.5;z-index: -1;}
  &__Text {position: relative;top: 50%;transform:translateY(-50%);
    h4 {font-size: 1.125em;line-height: 1.35;font-weight: normal;color: $white;letter-spacing: 0.2em;text-transform: uppercase;font-family: $second;margin-bottom: 0;}
    p {color: $white;font-size: 14px;letter-spacing: 0.2em;margin-top: 0.5em;font-family: $second;}
    strong {font-weight: 700;color: $white;}
    i {color: $white;position: absolute;font-size: 24px;margin-left: -50px;}
    a {color: $white;font-size: 14px;letter-spacing: 0.2em;margin-top: 0.5em;font-family: $second;text-decoration: none;}
  }
  &__More {padding:20px 30px;text-transform: initial;color: $black;vertical-align: middle;border: 1px solid $black;border-top: 0;border-bottom: 0;position: relative;z-index: 1;outline:none;text-decoration: none;text-transform: uppercase;font-weight: lighter;display: inline-block;
          &:before {content: '';position: absolute;top: 0;left: 0;right: 0;height: 1px;background: $black;z-index: -1;transition:300ms;}
          &:after {content: '';position: absolute;bottom: 0;left: 0;right: 0;height: 1px;background: $black;z-index: -1;transition:300ms;}
          &:hover {
            &:before {right: 30%;}
            &:after {left: 30%;}
          }
    }
}
.team {float: left;width: 100%;padding-bottom: 100px;
  &__Box {text-align: center;float: left;width: 25%;padding: 0 3%;
    &--img {float: left;width: 100%;height: 300px;background-size: cover;background-repeat: no-repeat;background-position: center center;}
    &--name {float: left;width: 100%;
      span {float: left;width: 100%;font-size: 14px;font-weight: lighter;}
    }
    &--info {float: left;width: 100%;
      a {text-decoration: none;color: $black;font-size: 14px;}
      p {margin: 0 !important;}
    }
  }
}