.footerCopy {float: left;width: 100%;background: $black;padding: 20px 0;border-top: 2px solid $brand;
  &__Left {float: left;width: 50%;}
  &__Right {float: left;width: 50%;text-align: right;}
  span {font-size: 14px;color: $white;font-weight: lighter;font-family: $second;}
  a {text-decoration: none;color: $white;font-size: 14px;font-weight: lighter;font-family: $second;
    &:hover {
      i {color: $brand;}
    }
  }
  img {max-width: 25px;vertical-align: middle;transition:300ms;}
}
.contactUs {
  .acf-map {float: left;width: 100%;height: 500px;} 
}
.contactMap {
  float: left;width: 50%;
  .acf-map {float: left;width: 100%;height: 500px;}
}